import { defineStore } from 'pinia';
import { User, UserFormDto, UserRole } from '@/models/User';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import jwtDecode from 'jwt-decode';
import i18n from '@/i18n';

export const useProfileStore = defineStore('profile-store', {
  state: () => ({
    authUserToken: '' as string,
    profile: undefined as User | undefined,
  }),
  getters: {
    authToken: (applicationState: any) => applicationState.authUserToken,
    externalId: (applicationState: any) => {
      return getExternalIdFromToken(applicationState.authUserToken);
    },
    userId: (applicationState: any) => {
      return getDataFromToken(applicationState.authUserToken, `${process.env.VUE_APP_AUTH0_API}userId`);
    },
    specialistId: (applicationState: any) => {
      return getDataFromAccountContext(applicationState.authUserToken, 'professionalId');
    },
    accountId: (applicationState: any) => {
      return getDataFromAccountContext(applicationState.authUserToken, 'accountId');
    },
    isAdmin: (applicationState: any) => tokenHasRole(applicationState.authUserToken, UserRole.ADMIN),
    isReceptionist: (applicationState: any) => tokenHasRole(applicationState.authUserToken, UserRole.RECEPTIONIST),
    isSaasUser: (applicationState: any) =>
      tokenHasRole(applicationState.authUserToken, UserRole.ADMIN) ||
      tokenHasRole(applicationState.authUserToken, UserRole.RECEPTIONIST) ||
      tokenHasRole(applicationState.authUserToken, UserRole.SPECIALIST),
    isSpecialist: (applicationState: any) => tokenHasRole(applicationState.authUserToken, UserRole.SPECIALIST),
    userRoles: (applicationState: any) => {
      return getDataFromAccountContext(applicationState.authUserToken, 'roles');
    },
    timezone: (applicationState: any) => {
      return getDataFromToken(applicationState.authUserToken, 'timezone');
    },
    language: (applicationState: any) => {
      return getDataFromToken(applicationState.authUserToken, 'language');
    },
    getProfile: (applicationState: any) => applicationState.profile,
  },
  actions: {
    async saveTokenAndProfile(token: string) {
      this.authUserToken = token;
      await this.fetchProfile();
    },

    deleteTokenAndProfile() {
      this.authUserToken = '';
      this.profile = undefined;
    },

    async fetchProfile() {
      const response = await userService.findSelf();
      if (!(response instanceof ServiceError)) {
        this.profile = response;
        i18n.global.locale.value = response.language;
      }
      return response;
    },

    async modifyProfile(user: UserFormDto) {
      const response = await userService.modifySelf(user);
      if (!(response instanceof ServiceError)) {
        this.profile = response;
      }
      return response;
    },
  },
});

function tokenHasRole(token: string, role: UserRole) {
  if (!token || token == '') return false;
  try {
    const decodedToken: any = jwtDecode(token);
    if (
      decodedToken &&
      decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`] &&
      decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`][0]
    ) {
      return decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`][0].roles.includes(role);
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

function getDataFromAccountContext(token: string, dataKey: string) {
  if (!token || token == '') {
    return undefined;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    if (
      decodedToken &&
      decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`] &&
      decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`][0]
    ) {
      return decodedToken[`${process.env.VUE_APP_AUTH0_API}accountContexts`][0][dataKey];
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

function getDataFromToken(token: string, dataKey: string) {
  if (!token || token == '') {
    return undefined;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken && decodedToken[dataKey]) {
      return decodedToken[dataKey];
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

function getExternalIdFromToken(token: string) {
  if (!token || token == '') {
    return undefined;
  }
  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken && decodedToken.sub) {
      return encodeURIComponent(decodedToken.sub);
    }
  } catch (error) {
    console.error(error);
    return '';
  }
  return undefined;
}
