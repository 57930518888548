<template>
  <Toast></Toast>
  <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
    <div class="layout-content-wrapper">
      <AppTopBar
        :topbar-notification-menu-active="topbarNotificationMenuActive"
        :topbar-user-menu-active="topbarUserMenuActive"
        @menu-button-click="onMenuButtonClick"
        @topbar-user-menu="onTopbarUserMenuButtonClick"
        @logout-button-click="onLogoutButtonClick"
      ></AppTopBar>

      <div class="layout-content">
        <router-view />
      </div>
    </div>

    <AppMenu
      v-if="menu"
      :model="menu"
      :layout-mode="layoutMode"
      :active="menuActive"
      :mobile-menu-active="staticMenuMobileActive"
      @menu-click="onMenuClick"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    ></AppMenu>

    <div class="layout-mask modal-in"></div>
  </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from './components/AppTopbar';
import AppMenu from './components/AppMenu';
import { inject } from 'vue';

export default {
  components: {
    AppTopBar,
    AppMenu,
  },
  inject: ['Auth'],
  data() {
    return {
      auth: inject('Auth'),
      menuActive: false,
      layoutMode: 'static',
      colorScheme: 'light',
      menuTheme: 'layout-sidebar-white',
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      userMenuClick: false,
      topbarUserMenuActive: false,
      notificationMenuClick: false,
      topbarNotificationMenuActive: false,
      configActive: false,
      configClick: false,
      menu: [
        {
          label: 'breadcrumb.dashboard',
          icon: 'icon-bar_chart_circle',
          to: '/',
        },
        {
          label: 'breadcrumb.patientList',
          icon: 'icon-group',
          to: '/patients',
        },
        {
          label: 'breadcrumb.chats',
          icon: 'icon-chat',
          to: '/chats',
        },
        {
          label: 'breadcrumb.supplementation',
          icon: 'icon-capsules',
          to: '/supplementation',
        },
      ],
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-slim': this.layoutMode === 'slim',
          'layout-sidebar-dim': this.colorScheme === 'dim',
          'layout-sidebar-dark': this.colorScheme === 'dark',
          'layout-overlay-active': this.overlayMenuActive,
          'layout-mobile-active': this.staticMenuMobileActive,
          'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': !this.$primevue.config.ripple,
        },
        this.colorScheme === 'light' ? this.menuTheme : '',
      ];
    },
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick() {
      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim()) {
          EventBus.emit('reset-active-index');
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isSlim()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },

    onLogoutButtonClick() {
      this.auth.logout(window.location.origin);
    },

    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll');
      } else {
        document.body.className += ' blocked-scroll';
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll');
      } else {
        document.body.className = document.body.className.replace(
          new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' ',
        );
      }
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isOverlay() {
      return this.layoutMode === 'overlay';
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'App.scss';

.layout-content {
  padding: 0;
  background: var(--lavender-100);
}

.layout-wrapper.layout-static .layout-content-wrapper {
  margin-left: 4rem;
}
</style>
