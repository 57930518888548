import { useProfileStore } from '@/store/profile.module';
import createAuth0Client, { Auth0Client, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import moment from 'moment';
import { App, Plugin, computed, reactive, watchEffect } from 'vue';
import { NavigationGuardWithThis } from 'vue-router';
import { SignOptions, sign } from 'jsonwebtoken';

let client: Auth0Client;
let storeProfile: any;
declare let zE: any;

interface Auth0PluginState {
  loading: boolean;
  isAuthenticated: boolean;
  error: any;
}

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  error: null,
});

function loginWithRedirect(o: RedirectLoginOptions) {
  return client.loginWithRedirect(o);
}

function logout(returnTo: string) {
  zE('messenger', 'logoutUser');
  storeProfile?.deleteTokenAndProfile();
  const options: LogoutOptions = {
    returnTo: returnTo, // URL permitida listada en el dashboard para el logout
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
  };
  return client.logout(options);
}

function resetPassword(email: string) {
  const axios = require('axios').default;
  const options = {
    method: 'POST',
    url: `https://${process.env.VUE_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      email: email,
      connection: 'Username-Password-Authentication',
    },
  };

  return axios.request(options);
}

const authPlugin = {
  loading: computed(() => state.loading),
  isAuthenticated: computed(() => state.isAuthenticated),
  loginWithRedirect,
  logout,
  resetPassword,
};

function userHasGrants(grants: string[]) {
  const userRoles = storeProfile?.userRoles as string[];
  if (!userRoles) {
    return false;
  }
  return userRoles.some((role) => grants.includes(role));
}

const routeGuard: NavigationGuardWithThis<undefined> = (to: any, _from: any, next: any) => {
  const { loading, isAuthenticated, loginWithRedirect } = authPlugin;

  const verify = async () => {
    if (isAuthenticated.value) {
      if (!storeProfile?.isSaasUser) {
        return next(`/error/access_denied/PROFESSIONAL`);
      }
      if (to.meta && to.meta.grants && !userHasGrants(to.meta.grants)) {
        return next(`/error/no-grants`);
      }
      return next();
    }
    await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!loading.value) {
    return verify();
  }

  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  redirectUri: string;

  onRedirectCallback(appState: any): void;
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  storeProfile = useProfileStore();

  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    redirect_uri: options.redirectUri,
  });

  storeProfile.deleteTokenAndProfile();

  try {
    // If the user is returning to the app after authentication
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    if (state.isAuthenticated) {
      const token = await client.getTokenSilently();
      await storeProfile?.saveTokenAndProfile(token);
      const profile = await storeProfile?.getProfile;

      const key = process.env.VUE_APP_ZENDESK_KEY;

      const payload = {
        name: profile?.name + ' ' + profile?.surname,
        email: profile?.email,
        email_verified: true,
        iat: moment().valueOf(),
        external_id: profile?.id,
        scope: 'user',
      };

      const signOptions: SignOptions = {
        keyid: process.env.VUE_APP_ZENDESK_KEY_ID,
      };

      if (key) {
        const tokenZendeskSigned = sign(payload, key, signOptions);

        zE('messenger', 'loginUser', function (callback: any) {
          callback(tokenZendeskSigned);
        });
      }
    }

    state.loading = false;
  }

  return {
    install: (app: App) => {
      app.provide('Auth', authPlugin);
    },
  };
}

interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>;
  routeGuard: NavigationGuardWithThis<undefined>;
}

export const Auth0: Auth0Plugin = {
  init,
  routeGuard,
};

export default authPlugin;
