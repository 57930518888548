
import { defineComponent, PropType } from '@vue/runtime-core';
import { ref } from 'vue';
import AboutUsModal from './AboutUsModal.vue';
import MenuComponent from './MenuComponent.vue';
export default defineComponent({
  components: { MenuComponent, AboutUsModal },
  emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
  props: {
    model: {
      type: Array as PropType<any[]>,
      required: true,
    },
    layoutMode: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    mobileMenuActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(_props, { emit }) {
    const logoUrl = ref(window.location.origin + '/assets/layout/images/logo-small.svg');
    const onMenuClick = (event: Event) => {
      emit('menu-click', event);
    };

    const onMenuItemClick = (event: Event) => {
      emit('menuitem-click', event);
    };
    const onRootMenuItemClick = (event: Event) => {
      emit('root-menuitem-click', event);
    };

    const aboutUsModalRef = ref();

    const onShowAboutUsModal = () => {
      aboutUsModalRef.value.openDialog();
    };

    return { logoUrl, onMenuClick, onMenuItemClick, onRootMenuItemClick, aboutUsModalRef, onShowAboutUsModal };
  },
});
